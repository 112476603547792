<script lang="ts" setup>
import type { Game } from "@/types";

type GameItem = {
	slug: string;
	type: string;
};

const props = defineProps<{
	itemSection: number;
}>();

const isGuest = useIsGuest();
const { select } = useGamesState();
const { homePageData, lastGames } = useHomePage();

const games = computed(() => homePageData.value?.schemeSections[props.itemSection] || []);

const ROWS_MAP: Record<string, number> = {
	recommend: 1,
	popularGames: 2,
	newGames: 2,
	bonusBuy: 1,
	fishing: 1,
	holdNLink: 1,
	intoTheWild: 1
};

const isRecentlyPlayedSection = (game: GameItem) => game?.slug === "last" && !isGuest.value && lastGames.value?.length;

const prepareGames = (game: GameItem) => {
	const columns = ROWS_MAP[camelCase(game?.slug ?? "")] ?? 1;
	const games = select(homePageData.value?.games[camelCase(game?.slug ?? "")]);
	if (columns > 1) {
		return games?.reduce<Array<Array<Game>>>((acc, item, index) => {
			const chunkIndex = Math.floor(index / columns);
			if (!acc[chunkIndex]) {
				acc[chunkIndex] = [];
			}
			acc[chunkIndex].push(item);
			return acc;
		}, []);
	}
	return games;
};

const isPrerenderedComponent = (count: number) => count <= 2;
</script>

<template>
	<div v-if="games.length" class="section-item">
		<template v-for="(game, index) in games" :key="index">
			<LazyOGamesSlider
				v-if="isRecentlyPlayedSection(game)"
				class="section-item row-games"
				:games="lastGames"
				title="homePage.gamesCategory.recentlyPlayed"
				icon="24/recent"
			/>
			<OLazyComponent
				v-else-if="!!homePageData?.games[camelCase(game?.slug ?? '')]?.length"
				:id="`home-game-${game?.slug}`"
				:prerender="itemSection === 0 ? isPrerenderedComponent(index) : false"
			>
				<OGamesSlider
					:games="prepareGames(game)"
					:title="homePageData?.gamesInfo[camelCase(game?.slug ?? '')]?.title || ''"
					:icon="homePageData?.gamesInfo[camelCase(game?.slug ?? '')]?.logo || ''"
					showAll
					isAdminIcon
					@view-all-action="navigateTo(`/issues/${game?.slug}/`)"
				/>
			</OLazyComponent>
		</template>
	</div>
</template>

<style lang="scss" scoped>
.section-item {
	display: flex;
	flex-direction: column;
	gap: 42px;
	@include media-breakpoint-up(md) {
		gap: 36px;
	}
}
</style>
